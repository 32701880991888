import { flow, makeAutoObservable, when } from "mobx";
import { RootStore } from "./rootStore";
import { custodialWalletSetting } from "src/types.enums";
import { makePersistable } from "mobx-persist-store";
import { TCryptoNumber, toNanoton } from "src/utils";
import { TCreateTonTransactionPayloadResponse } from "src/types";
import {
  CHAIN,
  THEME,
  TonConnectUI,
  TonProofItemReplySuccess,
  WalletsModalState,
} from "@tonconnect/ui";
import { identityClient, TTonOwnershipProofRequest } from "src/clients";
import { frameService, ownHost, TonChainId } from "src/services";

const isProofReplySuccess = (value: any): value is TonProofItemReplySuccess =>
  value?.name === "ton_proof";

const getWeb3AuthToken = async () => {
  const response = await identityClient.getTonNonce();

  if (response.error) {
    throw response.error;
  }

  return response.payload!;
};

// "ton-connect-storage_bridge-connection": string | undefined;
// "ton-connect-ui_preferred-wallet": string | undefined;
// "ton-connect-ui_last-selected-wallet-info": string | undefined;
// "ton-connect-ui_wallet-info": string | undefined;
export const getTonConnectData = () => {
  const data: { [key: string]: string | null } = {};

  for (let [key, value] of Object.entries(localStorage)) {
    if (key.startsWith("ton-connect")) {
      data[key] = value;
    }
  }

  return data;
};

export class TonStore {
  _address?: string;
  _flowInProgress: string | null = null;
  _tonConnectUI?: TonConnectUI;
  _nonceRefreshTimeout?: ReturnType<typeof setTimeout>;
  _unsubscribeTonModalEvents?: () => void;
  _callbacks: (() => void)[] = [];

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: "TonStore",
      properties: ["_address"],
    });
  }

  get tonConnectUI() {
    if (!this._tonConnectUI) {
      this._tonConnectUI = new TonConnectUI({
        manifestUrl: `${ownHost}/tonconnect-manifest.json`,
        uiPreferences: {
          theme: THEME.DARK,
        },
        actionsConfiguration: {
          returnStrategy: "back",
          // twaReturnUrl: "://",
        },
      });

      this._unsubscribeTonModalEvents = this._tonConnectUI.onModalStateChange(
        async ({ status, closeReason }: WalletsModalState) => {
          console.log("tonConnectUI", { status, closeReason });

          // possible states:
          // {"status":"opened","closeReason":null}
          // {"status":"closed","closeReason":"action-cancelled"}
          // {"status":"closed","closeReason":"wallet-selected"}

          if (status === "closed") {
            if (closeReason === "action-cancelled") {
              this._connectCanceled();
            } else if (closeReason === "wallet-selected") {
              this._onWalletSelected();
            }
          } else if (status === "opened") {
            console.log("TonConnectUI opened");
          } else {
            console.error(
              `Invalid TonConnect state: ${status}/${closeReason ?? "unknown"}`
            );
          }
        }
      );
    }

    return this._tonConnectUI;
  }

  connect = this.rootStore.singleCall(
    "TonStore.connect",
    flow(function* (rootStore: RootStore) {
      const $this = rootStore.tonStore;

      if ($this._flowInProgress) return;

      $this._flowInProgress = "tonConnectFlow";
      $this.tonConnectUI.setConnectRequestParameters({
        state: "loading",
      });

      yield $this.tonConnectUI.openModal();
      yield $this.setNonce();
    })
  );

  sendDeposit = this.rootStore.singleCall<
    string | undefined,
    [TCryptoNumber, string]
  >(
    "sendDeposit",
    flow(function* (
      rootStore: RootStore,
      amount: TCryptoNumber,
      message: string | null
    ) {
      const $this = rootStore.tonStore;
      const payload = {
        Amount: amount.value,
        Message: message,
      };
      const response: TCreateTonTransactionPayloadResponse =
        yield rootStore.connectionStore.createTonTransactionPayload(payload);

      let connected: boolean = yield $this._isWalletConnected();

      let resolver: () => void = () => {};
      const awaiter: Promise<void> = new Promise((resolve) => {
        resolver = () => {
          resolve();
        };
      });

      if (!connected) {
        $this._callbacks.push(resolver);

        yield $this.connect();
        yield awaiter;

        connected = yield $this._isWalletConnected();

        if (!connected) {
          return;
        }
      }

      return yield $this.sendTransaction({
        address: response.ContractAddress,
        payload: response.Payload,
      });
    })
  );

  private _fireCallbacks = () => {
    const callbacks = [...this._callbacks];
    this._callbacks = [];

    for (var callback of callbacks) callback();
  };

  private _connectCanceled = () => {
    frameService.close();
    this.stopRerefreshNonce();
    this._fireCallbacks();
  };

  private _onWalletSelected = async () => {
    const wallet = this.tonConnectUI.wallet;

    if (!wallet) {
      console.log("Wallet not selected");
      await this.reconnect();

      return;
    }
    const account = wallet.account;

    if (account.chain !== TonChainId) {
      this.showMessage(
        account.chain === CHAIN.MAINNET
          ? "Switch to testnet"
          : "Switch to mainnet"
      );

      return;
    }

    const tonProof = wallet.connectItems?.tonProof;

    if (!isProofReplySuccess(tonProof)) {
      console.log("TonProof not received");
      await this.reconnect();

      return;
    }

    const checkProofRequest: TTonOwnershipProofRequest = {
      address: account.address,
      chain: account.chain,
      publicKey: account.publicKey,
      timestamp: tonProof.proof.timestamp,
      signature: tonProof.proof.signature,
      payload: tonProof.proof.payload,
      walletStateInit: account.walletStateInit,
      domain: tonProof.proof.domain.value,
    };

    const { payload, error } = await identityClient.checkTonProof(
      checkProofRequest
    );

    if (error) {
      this.showMessage(error);
    }

    if (payload) {
      await this.ensureAccountCreated(
        payload!.token,
        checkProofRequest.address
      );
    }

    this.stopRerefreshNonce();
    this._fireCallbacks();
  };

  private _isWalletConnected = async (): Promise<boolean> => {
    return (
      this.tonConnectUI.connected ||
      (await this.tonConnectUI.connectionRestored)
    );
  };

  private reconnect = this.rootStore.singleCall(
    "TonStore.reconnect",
    flow(function* (rootStore: RootStore) {
      const $this = rootStore.tonStore;

      if (!$this._flowInProgress) return;

      $this._flowInProgress = null;
      // $this._unsubscribeTonModalEvents!();

      yield $this.tonConnectUI.disconnect();
      yield $this.connect();
    })
  );

  private async sendTransaction({
    address,
    payload,
  }: {
    address: string;
    payload?: string;
  }) {
    try {
      this.tonConnectUI.setConnectRequestParameters({
        state: "loading",
      });
      const result = await this.tonConnectUI.sendTransaction(
        {
          validUntil: Math.floor(Date.now() / 1000) + 300, // 5 minutes
          network: TonChainId,
          messages: [
            {
              address: address,
              amount: toNanoton(0.05).str,
              payload,
            },
          ],
        },
        {
          // modals: ["before", "success", "error"],
          // notifications: ["before", "success", "error"],
        }
      );

      console.log("Send ton transaction result", { ...result });

      return result.boc;
    } catch (e) {
      console.error(e);
    } finally {
      try {
        this.tonConnectUI.setConnectRequestParameters({
          state: "ready",
          value: {},
        });

        this.tonConnectUI.closeModal();
      } catch (error) {
        console.error("Failed to close tonUI modal", error);
      }
    }
  }

  private setNonce = async () => {
    if (this._nonceRefreshTimeout) return;

    const { nonce, expireAtSec } = await getWeb3AuthToken();

    this.tonConnectUI.setConnectRequestParameters({
      state: "ready",
      value: {
        tonProof: nonce,
      },
    });

    const expireAt = new Date(expireAtSec * 1000);
    const now = new Date();
    const diffSeconds = expireAt.getTime() - now.getTime();

    this._nonceRefreshTimeout = setTimeout(this.setNonce, diffSeconds);
  };

  private stopRerefreshNonce = () => {
    clearTimeout(this._nonceRefreshTimeout);
    this._nonceRefreshTimeout = undefined;
  };

  private ensureAccountCreated = this.rootStore.blockingCall(
    flow(function* (
      rootStore: RootStore,
      accessToken: string,
      address: string
    ) {
      const hasUser = yield rootStore.userStore._setAccessToken(accessToken);

      if (!hasUser) {
        console.error("Identity request failed");

        return;
      }

      yield when(() => rootStore.connectionStore.connected);

      try {
        const {
          walletStore: {
            hasBsvAccount,
            createPkAndSave,
            getAccount,
            createWeb3Account,
          },
        } = rootStore;

        if (!hasBsvAccount) {
          yield createPkAndSave();
          yield rootStore.connectionStore.updateSetting({
            Type: custodialWalletSetting,
            Value: "true",
          });
        }

        if (!getAccount("Ton", "Usdt")) {
          yield createWeb3Account(address, "Ton", "Usdt");
        }
      } catch (error) {
        rootStore.tonStore.showMessage(
          error instanceof Error ? error.message : `${error}`
        );
      }
    })
  );

  private showMessage = (message: string) =>
    this.rootStore.notificationStore.addNotification(message);
}
