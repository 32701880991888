import { TCryptoAsset } from "src/types.enums";
import { StringUnion } from "src/utils";

export type TPacket = string | null;

export type TToken = {
  tokenId: string;
  name: string;
  amount: number;
  amountUsd: number;
  currency: TCryptoAsset;
  order: number;
};

export type TBalancesMessage = {
  pendingBalance: number;
  bountyBalance: TToken;
  activeTokens: TToken[];
  tokenWithMaxBalance: TToken;
};

export type TUserMessage = {
  email: string;
  username: string;
  bsvAccountAddress: string;
  provider: string;
  token: string;
  packet: TPacket;
};

export type TAuthorizedMessage = {
  user: TUserMessage;
  balances: TBalancesMessage;
};

export enum WalletEvent {
  Authorized = "AUTHORIZED",
  Balances = "BALANCES",
  BalancesRefreshed = "BALANCES_REFRESHED",
  BountyBalance = "BOUNTY_BALANCE",
  Closed = "CLOSED",
  Declined = "DECLINED",
  Deposit = "DEPOSIT",
  Error = "ERROR",
  LoginRequired = "LOGIN_REQUIRED",
  LogoutCompleted = "LOGOUT_COMPLETED",
  OpenMe = "OPEN_ME",
  OpenLink = "OPEN_LINK",
  PendingBalance = "PENDING_BALANCE",
  Ready = "READY",
  SubscribedBalanceChanges = "SUBSCRIBE_BALANCE_CHANGES",
  TonBalance = "BALANCE:TON",
  TransactionSent = "REFILL_COMPLETED",
  Withdrawal = "WITHDRAWAL",
  Unknown = "UNKNOWN",
}

export const WalletEvents = StringUnion(
  WalletEvent.Authorized,
  WalletEvent.Balances,
  WalletEvent.BalancesRefreshed,
  WalletEvent.BountyBalance,
  WalletEvent.Closed,
  WalletEvent.Declined,
  WalletEvent.Deposit,
  WalletEvent.Error,
  WalletEvent.LoginRequired,
  WalletEvent.LogoutCompleted,
  WalletEvent.OpenMe,
  WalletEvent.OpenLink,
  WalletEvent.PendingBalance,
  WalletEvent.Ready,
  WalletEvent.SubscribedBalanceChanges,
  WalletEvent.TonBalance,
  WalletEvent.TransactionSent,
  WalletEvent.Withdrawal,
  WalletEvent.Unknown
);

export enum ClientEvent {
  AppId = "APP_ID",
  Logout = "LOGOUT",
  SendTransaction = "REFILL",
  SendTransactionTon = "REFILL:TON",
  SubscribeBalanceChanges = "SUBSCRIBE_BALANCE_CHANGES",
  TonBalance = "BALANCE:TON",
  Topup = "TOPUP",
  ViewDeposit = "VIEW:DEPOSIT",
  ViewWallet = "VIEW:WALLET",
}

export const ClientEvents = StringUnion(
  ClientEvent.AppId,
  ClientEvent.Logout,
  ClientEvent.SendTransaction,
  ClientEvent.SendTransactionTon,
  ClientEvent.SubscribeBalanceChanges,
  ClientEvent.TonBalance,
  ClientEvent.Topup,
  ClientEvent.ViewDeposit,
  ClientEvent.ViewWallet
);

export type TClientEvent = typeof ClientEvents.type;
