import { RootStore, rootStore } from "src/store";
import {
  envHelper,
  iFrameMasterHost,
  liquidityPoolAddress,
  marginPoolAddress,
} from "../config-service";
import { reaction, when } from "mobx";
import { history, routes } from "../routes-service";
import { sleep, unwrapError } from "src/utils";
import { tryApplyPacket } from "./manage-packet";
import {
  buildAllBalancesMessage,
  buildAuthorizedMessage,
} from "./build-messages";
import { ClientEvent, TClientEvent, TToken, WalletEvent } from "./types";
import {
  Address,
  TransactionBuilderError,
  TransactionReader,
} from "dxs-stas-sdk";
import { number, object, string } from "yup";
import { BsvTokenId } from "src/types.enums";

export type RequestId = string | null;
export type RequestPayload = { packet: string | undefined };

export type SelfMessageEvent = MessageEvent<{
  event: TClientEvent;
  payload: RequestPayload;
  requestId: RequestId;
}>;

export const isInFrame = () => window.self !== window.top;

enum SendToPool {
  MarginPool = "ACCOUNT",
  Liquiditypool = "POOL",
}

const sendRequestPayloadSchema = object().shape({
  amount: number().required(),
  tokenId: string(),
  message: string().required(),
  type: string()
    .required()
    .oneOf(
      Object.values(SendToPool),
      `Type must be one of: [${Object.values(SendToPool).join(", ")}]`
    ),
});

const sendTonTransactionRequestPayloadSchema = object().shape({
  amount: number().required(),
  message: string().required(),
});

const windowOpen = window.open;

window.open = (
  url?: string | URL,
  target?: string,
  features?: string
): WindowProxy | null => {
  if (!isInFrame()) return windowOpen(url, target, features);

  frameService.openLink(url, target, features);

  return null;
};

export class FrameService {
  transactionInProgress = false;

  constructor(private rootStore: RootStore) {
    if (!isInFrame()) return;

    reaction(
      () => this.rootStore.connectionStore.connected,
      async (connected) => {
        if (!connected) return;

        if (!this.rootStore.walletStore.hasWallet) {
          await when(() => this.rootStore.walletStore.hasWallet);
        }

        this.rootStore._lock++;

        if (!this.rootStore.walletStore.hasBsvAccount) {
          await when(() => this.rootStore.walletStore.hasBsvAccount);
        }

        this.sendMessage(
          null,
          WalletEvent.Authorized,
          await buildAuthorizedMessage(this.rootStore)
        );
        await sleep(100);

        this.rootStore._lock--;
      },
      { fireImmediately: true }
    );
  }

  isInFrame = () => window.self !== window.top;

  close = () => {
    this.sendMessage(null, WalletEvent.Closed, null);
  };

  decline = () => {
    this.sendMessage(null, WalletEvent.Declined, null);
  };

  openMe = (requestId: RequestId) => {
    this.sendMessage(requestId, WalletEvent.OpenMe, null);
  };

  openLink = (url?: string | URL, target?: string, features?: string) => {
    this.sendMessage(null, WalletEvent.OpenLink, { url, target, features });
  };

  withdrawal = (amount: number) => {
    this.sendMessage(null, WalletEvent.Withdrawal, { amount });
  };

  deposit = (amount: number) => {
    this.sendMessage(null, WalletEvent.Deposit, { amount });
  };

  error = (message: string) => {
    this.sendMessage(null, WalletEvent.Error, {
      message,
    });
  };

  public init = async () => {
    window.addEventListener("message", (message) => {
      try {
        this.handleMessage(message);
      } catch (e) {
        console.error("Error in handleMessage #frameService #FIORIN", e);
      }
    });

    this.ensureHasAccess(null, "INIT");
    this.sendMessage(null, WalletEvent.Ready, null);
  };

  private ensureHasAccess = (requestId: RequestId, from: string): boolean => {
    const {
      walletStore: { hasBsvAccount, hasWallet },
      userStore: { IsLoggedIn },
    } = this.rootStore;

    if (!IsLoggedIn || !hasBsvAccount || !hasWallet) {
      this.sendMessage(requestId, WalletEvent.LoginRequired, from);
      return false;
    }

    return true;
  };

  private handleMessage = async ({
    data: { event, payload, requestId = null },
  }: SelfMessageEvent) => {
    if (!event) {
      return;
    }

    console.log("handleMessage in iframe:", event, payload);

    if (!requestId) {
      console.log("requestId is not defined #handleMessage #frameService");
    }

    try {
      if (event === ClientEvent.AppId) {
        await tryApplyPacket(this.rootStore, `${payload.packet}`);
      }

      if (!this.ensureHasAccess(requestId, "handleMessage")) {
        console.log(
          "Has no access. #handleMessage #frameService",
          event,
          payload,
          requestId
        );
        return;
      }

      if (event === ClientEvent.AppId) {
        this.sendMessage(requestId, WalletEvent.Unknown, true);

        return;
      }

      switch (event) {
        case ClientEvent.Logout:
          const {
            rootStore: {
              userStore: { signOut },
            },
          } = this;

          await signOut();
          await sleep(500);

          this.sendMessage(requestId, WalletEvent.LogoutCompleted, null);
          break;

        case ClientEvent.SendTransaction:
          await this.sendTransaction(requestId, payload);
          break;

        case ClientEvent.SendTransactionTon:
          await this.sendTonTransaction(requestId, payload);
          break;

        case ClientEvent.Topup:
        case ClientEvent.ViewDeposit:
          history.push(routes.wallet.money.uri("deposit"));

          await sleep(100);
          this.openMe(requestId);

          break;

        case ClientEvent.ViewWallet:
          history.push(routes.wallet.path);

          await sleep(100);
          this.openMe(requestId);

          break;

        case ClientEvent.TonBalance:
          const balance =
            await this.rootStore.connectionStore.getTonWalletBalance();
          const config = this.rootStore.walletStore.getAssetConfig(
            "Ton",
            "Usdt"
          );
          const scheme = config.assetConfig.scheme;
          const tonBalanceResponse: TToken = {
            tokenId: config.assetConfig.scheme.TokenId,
            name: scheme.Name,
            amount: balance,
            amountUsd: balance,
            currency: config.assetConfig.cryptoAsset,
            order: 0,
          };

          this.sendMessage(
            requestId,
            WalletEvent.TonBalance,
            tonBalanceResponse
          );

          break;

        case ClientEvent.SubscribeBalanceChanges:
          reaction(
            () => this.rootStore.walletStore.listAssets,
            () => {
              this.sendBalances(null, WalletEvent.Balances);
            }
          );

          reaction(
            () => this.rootStore.walletStore.balancesChanged,
            () => {
              this.sendBalances(null, WalletEvent.Balances);
            },
            { fireImmediately: true }
          );

          reaction(
            () => this.rootStore.historyStore.pendingDeposit,
            () => {
              this.sendBalances(null, WalletEvent.PendingBalance);
            }
          );

          reaction(
            () => this.rootStore.walletStore.bountyBalance,
            () => {
              this.sendBalances(null, WalletEvent.BountyBalance);
            }
          );

          this.sendMessage(
            requestId,
            WalletEvent.SubscribedBalanceChanges,
            null
          );
          break;
      }
    } catch (error) {
      this.sendMessage(requestId, WalletEvent.Error, {
        message: (error as Error).message,
      });
    }
  };

  private sendTransaction = async (requestId: RequestId, payload: unknown) => {
    if (this.transactionInProgress) {
      throw new Error("Transaction in progress");
    }

    this.transactionInProgress = true;

    try {
      const { amount, tokenId, message, type } =
        await sendRequestPayloadSchema.validate(payload);

      const isStasTransaction =
        tokenId !== undefined && tokenId !== null && tokenId !== BsvTokenId;

      const assetKey =
        this.rootStore.walletStore.assetKeyByTokenId[tokenId || BsvTokenId];
      const assetConfig = this.rootStore.walletStore.assetConfigs[assetKey];

      const minAmount = 1 / assetConfig.scheme.SatoshisPerToken;

      if (amount < minAmount) {
        throw Error(`Amount must be greater or equal to ${minAmount}`);
      }

      const addressStr =
        type === SendToPool.MarginPool
          ? marginPoolAddress
          : type === SendToPool.Liquiditypool
          ? liquidityPoolAddress
          : "";

      const address = Address.fromBase58(addressStr);
      const {
        transactionStore: {
          prepareBsvTransaction,
          prepareStasBundle,
          broadcast,
          setBundleToSend,
          setTransactionToSend,
        },
      } = this.rootStore;

      const note = message
        ? message.split(" ").map((x: string) => Buffer.from(x, "utf8"))
        : undefined;
      let txRaw: string;

      if (isStasTransaction) {
        const bundle = await prepareStasBundle(
          assetConfig,
          amount,
          address,
          note
        );

        if (bundle.message) {
          throw new Error(
            envHelper.isProduction() ? bundle.message : bundle.devMessage
          );
        }

        setBundleToSend(bundle);

        txRaw = bundle.transactions![bundle.transactions!.length - 1];
      } else {
        const tx = await prepareBsvTransaction(amount, address, note);
        txRaw = tx.toHex();

        setTransactionToSend(tx);
      }

      await broadcast(tokenId);

      const tx = TransactionReader.readHex(txRaw);

      this.sendMessage(requestId, WalletEvent.TransactionSent, {
        txId: tx.Id,
        amount,
        address: address.Value,
        txs: [tx.Id],
      });
    } catch (error) {
      let message;

      if (error instanceof TransactionBuilderError) {
        if (!envHelper.isProduction()) {
          message = error.devMessage;
        } else {
          message = error.message;
        }
      } else {
        message = unwrapError(error);
      }

      console.log(
        "Error in sendTransaction #frameService ",
        "error:",
        error,
        "input payload:",
        payload
      );

      this.sendMessage(requestId, WalletEvent.Error, message);
    } finally {
      this.transactionInProgress = false;
    }
  };

  private sendTonTransaction = async (
    requestId: RequestId,
    payload: unknown
  ) => {
    try {
      const { amount, message } =
        await sendTonTransactionRequestPayloadSchema.validate(payload);

      this.sendMessage(null, WalletEvent.OpenMe);

      await sleep(100);

      const txId = await this.rootStore.tonStore.sendDeposit(
        { value: amount, str: amount.toString() },
        message
      );

      if (txId) {
        this.sendMessage(requestId, WalletEvent.TransactionSent, {
          txId,
        });
      } else {
        this.sendMessage(requestId, WalletEvent.Error, {
          message: "Payment canceled",
        });
      }
    } catch (error) {
      const message = unwrapError(error);

      console.log(
        "Error in sendTransactionTon #frameService ",
        "error:",
        error,
        "input payload:",
        payload
      );

      this.sendMessage(requestId, WalletEvent.Error, message);
    }
  };

  private sendMessage = (
    requestId: RequestId,
    event: WalletEvent,
    payload: unknown = {}
  ) => {
    const message = { requestId, event, payload };

    if (!envHelper.isProduction()) {
      console.log("sendMessage from iframe #frameService:", message);
    }

    window.parent.postMessage(message, iFrameMasterHost);
  };

  private sendBalances = (requestId: RequestId, event: WalletEvent) => {
    const message = buildAllBalancesMessage(this.rootStore);

    this.sendMessage(requestId, event, message);
  };
}

export const frameService = new FrameService(rootStore);
