import { configure, flow, get, makeAutoObservable, set, when } from "mobx";
import { configurePersistable } from "mobx-persist-store";
import { BridgeStore } from "./bridgeStore";
import { HistoryStore } from "./historyStore";
import { UserStore } from "./userStore";
import { WalletStore } from "./walletStore";
import { MnemonicStore } from "./mnemonicStore";
import { NotificationStore } from "./notificationStore";
import { ConnectionStore } from "./connectionStore";
import { TransactionStore } from "./transactionStore";
import { frameService, isInFrame } from "src/services";
import { isString } from "src/utils";
import { CompensationsStore } from "./compensationsStore";
import { ClientStore } from "./clientStore";
import { RatesStore } from "./ratesStore";
import { TonStore } from "./tonStore";

configure({
  // enforceActions: "never",
  computedRequiresReaction: false,
  reactionRequiresObservable: false,
  observableRequiresReaction: false,
});

configurePersistable({
  storage: window.localStorage,
  stringify: true,
});

export class RootStore {
  bridgeStore: BridgeStore;
  clientStore: ClientStore;
  compensationsStore: CompensationsStore;
  connectionStore: ConnectionStore;
  historyStore: HistoryStore;
  mnemonicStore: MnemonicStore;
  notificationStore: NotificationStore;
  ratesStore: RatesStore;
  tonStore: TonStore;
  transactionStore: TransactionStore;
  userStore: UserStore;
  walletStore: WalletStore;

  _lock: number = 0;
  isReady: boolean = false;

  _methodsInProgress: { [name: string]: boolean } = {};

  constructor() {
    this.userStore = new UserStore(this);
    this.connectionStore = new ConnectionStore(this);
    this.compensationsStore = new CompensationsStore(this);
    this.walletStore = new WalletStore(this);
    this.bridgeStore = new BridgeStore(this);
    this.clientStore = new ClientStore(this);
    this.historyStore = new HistoryStore(this);
    this.notificationStore = new NotificationStore();
    this.ratesStore = new RatesStore(this);
    this.tonStore = new TonStore(this);
    this.mnemonicStore = new MnemonicStore(this);
    this.transactionStore = new TransactionStore(this);

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get isLoading() {
    return this._lock !== 0;
  }

  blockingCall = <R, Args extends any[]>(
    action: (rootStore: RootStore, ...args: Args) => Promise<R>
  ) => {
    const rootStore = this;

    return flow<R, [...Args]>(function* (...args: Args) {
      try {
        rootStore._lock++;

        return yield action(rootStore, ...args);
      } finally {
        rootStore._lock--;
      }
    });
  };

  singleCall = <R, Args extends any[]>(
    methodName: string | ((...args: Args) => string),
    action: (rootStore: RootStore, ...args: Args) => Promise<R>
  ) => {
    const rootStore = this;

    return flow<R, [...Args]>(function* (...args: Args) {
      const key: string = isString(methodName)
        ? methodName
        : methodName(...args);

      if (get(rootStore._methodsInProgress, key)) {
        console.warn(`Method "${key}" is executing`);

        return;
      }

      console.log(`Start method "${key}"`);

      try {
        set(rootStore._methodsInProgress, key, true);

        return yield action(rootStore, ...args);
      } finally {
        set(rootStore._methodsInProgress, key, false);
        console.log(`Finished method "${key}"`);
      }
    });
  };

  _initStores = flow(function* (this: RootStore) {
    yield this.userStore.init();
    yield this.connectionStore.init();
    yield this.walletStore.init();

    yield Promise.allSettled([
      this.bridgeStore.init(),
      this.historyStore.init(),
      this.clientStore.init(),
    ]);

    yield when(() => this.connectionStore.isReady);

    if (isInFrame()) {
      yield frameService.init();
    }
  });

  init = this.singleCall(
    "initRootStore",
    flow(function* (rootStore: RootStore) {
      if (rootStore.isReady) return;

      try {
        const { _initStores, blockingCall } = rootStore;

        yield blockingCall(_initStores)();

        rootStore.isReady = true;
      } catch (error) {
        console.error("#FrameService", "init faield", error);
      }
    })
  );

  makeBlockingCallback = <R>(action: () => Promise<R>) =>
    this.blockingCall(action)();
}
