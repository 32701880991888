import { lazy, StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { frameService } from "./services";

const App = lazy(() => import("./App"));
// const EthApp = lazy(() => import("./App.eth"));

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

if (frameService.isInFrame()) {
  rootElement!.onclick = (e) => {
    if (e.target === rootElement) {
      frameService.close();
    }
  };
}

root.render(
  <StrictMode>
    <Suspense
      fallback={
        <div
          style={{
            height: "100vh",
            margin: "-8px",
            width: "100vw",
          }}
        />
      }
    >
      {/* {isInFrame() ? <FrameApp /> : <App />} */}
      <App />
    </Suspense>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import("./reportWebVitals").then((x) => x.default(console.log));
